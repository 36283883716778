<template>
  <div>
    <Sidebar />
    <div class="ps-2 mt-7 pt-7">
      <UserName />
    </div>

    <div style="margin-bottom: 10em">
      <HeaderNavigasi />

      <!-- BREADCRUMB -->
      <div class="d-flex flex-row ps-0">
        <div class="ml-2 ps-0">
          <v-breadcrumbs
            :items="items"
            large
            class="pt-0 ps-0 breadcrumb"
          ></v-breadcrumbs>
        </div>
      </div>

      <div v-if="isLoading" class="text-center mb-5">
        <SkeletonDetail />
      </div>

      <div v-else>
        <div v-if="itemData.is_alcohol_item == 1" class="mx-3">
          <v-img :lazy-src="warning" max-height="150" :src="warning"></v-img>
        </div>

        <!-- CARD ITEM -->

        <v-container style="margin-bottom: 1em">
          <div class="text-product">
            <h6>{{ itemData.item_name }}</h6>
          </div>
          <div
            class="text-merchant pb-1"
            @click="gotoShop(itemData.store_id)"
            style="cursor: pointer"
          >
            <v-icon size="20"> mdi-store </v-icon>
            <span>{{ storeName }}</span>
          </div>
          <div class="mb-2">
            <!-- <p style="font-size: 9px;">
              {{ $t("message.term-cond-store") }}.
              <span class="pink--text" @click.prevent="shopInfo(shopInfo_link.info_shop_url)">
                {{ $t("message.click") }}
              </span>
            </p> -->
            <p v-if="shopInfo_link.info_medic_url" style="font-size: 9px">
              医薬品販売について
              <span
                class="pink--text"
                style="cursor: pointer"
                @click.prevent="
                  $router.push(`/store/${itemData.store_id}/medic`)
                "
              >
                {{ $t("message.click") }}
              </span>
            </p>
          </div>
          <v-row>
            <v-col>
              <v-carousel
                v-model="model"
                height="343"
                contain
                transition="fade-transition"
                reverse-transition="fade-transition"
                hide-delimiter-background
                hide-delimiters
                style="position: relative"
              >
                <v-carousel-item
                  v-for="(slide_item, i) in slide"
                  :key="i"
                  contain
                  :src="slide_item"
                >
                </v-carousel-item>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="white"
                  style="position: absolute; bottom: 5%; left: 2%"
                  @click="addFavorite(itemData)"
                >
                  <v-icon dark :color="isFavorite()"> mdi-heart </v-icon>
                </v-btn>
              </v-carousel>
              <div class="text-price d-flex justify-end" style="color: #ff0090">
                <span style="font-size: 18px">
                  <span
                    style="font-size:16px;vertical-align: baseline;16px;margin-right: 10px;"
                  >
                    {{ $t("message.without-tax") }}
                  </span>
                  <span style="font-size: 18px">
                    {{ formatDecWithoutComa(itemData.price_without_tax) }}
                  </span>
                  <span
                    style="
                      font-size: 16px;
                      vertical-align: baseline;
                      margin-right: 10px;
                    "
                  >
                    円</span
                  >
                </span>
              </div>
              <div
                v-if="!conditionalPrice(itemData.price)"
                class="grey--text d-flex justify-end"
              >
                <div class="px-4">
                  <span style="margin-right: 10px">
                    {{ $t("message.with-tax") }}
                  </span>
                  <span style="font-size: 18px">
                    {{ formatDecWithoutComa(itemData.price) }} 円
                  </span>
                </div>
              </div>
              <div v-else class="grey--text d-flex justify-end">
                <span style="margin-right: 10px">
                  {{ $t("message.with-tax") }}
                </span>
                <span style="font-size: 18px">
                  {{ priceBeforeComa(itemData.price) }}
                  <span style="font-size: 12px; margin-left: -3px">
                    {{ priceAfterComa(itemData.price) }} 円
                  </span>
                </span>
              </div>

              <v-divider style="color: blue"></v-divider>

              <div class="mb-3 mt-5">
                <div style="font-size: 16px; font-weight: 500">
                  {{ $t("message.label-product-desc") }}
                </div>
                <div class="desc-class">
                  {{ itemData.description }}
                </div>
              </div>
              <div class="mb-3 mt-5">
                <div class="" style="font-size: 16px; font-weight: 500">
                  {{ $t("message.composition") }}
                </div>
                <div
                  v-html="specification"
                  class="desc-class"
                  style="white-space: pre-wrap"
                >
                  <!-- {{ itemData.specification }} -->
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- SIMILIAR PRODUCT -->
          <div class="mb-10 mt-10">
            <ProductSlide
              :data_slide="similarProduct"
              :title_slide="similiarProduct"
              :link_slide="`/similiar-product/${slug_product}`"
              :data_store="[]"
            />
          </div>
        </v-container>

        <!-- NAVIGATION FOOTER -->
        <v-container class="d-flex justify-center">
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" class="pb-0 d-flex justify-center">
              <v-btn
                color="grey darken-1"
                class="pa-2 white--text"
                depressed
                width="300"
                @click="
                  $router.push(`/items/category/${itemData.category_slug}`)
                "
              >
                {{ $t("message.back-to-category") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="pb-0 d-flex justify-center">
              <v-btn
                color="#ff0090"
                class="pa-2 white--text"
                depressed
                width="300"
                style="text-transform: none"
                @click="$router.push('/')"
              >
                {{ $t("message.back-to-home") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <Cashier :itemDetail="itemData" />
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import ProductSlide from "../components/ProductSlide.vue";
import LoadingPage from "../components/LoadingPage.vue";
import SkeletonDetail from "../components/skeleton/SkeletonDetail.vue";
import User from "../components/User.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
import Cashier from "../components/Cashier.vue";
import Warning from "../components/Warning.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import { mdiArrowLeft } from "@mdi/js";
import Swal from "sweetalert2";
import moneyFormat from "../helpers/moneyFormat";
import priceBeforeComa from "../helpers/priceBeforeComa";
import priceAfterComa from "../helpers/priceAfterComa";
import formatDecWithoutComa from "../helpers/formatDecWithoutComa";

export default {
  name: "Home",

  components: {
    Sidebar,
    LoadingPage,
    UserName: User,
    ProductSlide,
    Cashier,
    CategoryFooter,
    HeaderNavigasi,
    SkeletonDetail,
    Warning,
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      isLoading: true,
      model: 0,
      categoryName: "",
      categorySlug: "",
      parent_name: "",
      parent_slug: "",
      specification: "",
      slug_product: this.$router.history.current.params.slug,
      slug: "",
      message: {},
      notFound: require("../assets/image-not-found.png"),
      warning: require("../assets/stop20.png"),
      detail: {},
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  methods: {
    conditionalPrice(price) {
      let priceNum = String(price);
      let flag = false;
      for (let i = 0; i < priceNum.length; i++) {
        if (priceNum[i] === ".") {
          flag = true;
          break;
        }
      }
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    priceBeforeComa(price) {
      return priceBeforeComa(String(price));
    },
    priceAfterComa(price) {
      return priceAfterComa(String(price));
    },
    formatDecWithoutComa(price) {
      return formatDecWithoutComa(price);
    },
    addFavorite: function (data) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.$gtag.event("wishlist-item-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });
        let itemId = this.itemData.id;
        let slug = this.$router.history.current.params.slug;
        let payload = { item_id: itemId, slug: slug };
        this.$store.dispatch("itemFavorite_module/addItemFavorite", payload);
      }
    },
    async itemDetail() {
      this.isLoading = true;
      let slug = this.$router.history.current.params.slug;
      let data = await this.$store.dispatch(
        "itemDetail_module/itemDetail",
        slug
      );
      this.isLoading = false;
      return data;
    },
    isFavorite() {
      if (this.itemData.has_favourited) {
        return "#ff0090";
      } else {
        return "grey";
      }
    },
    gotoShop(id) {
      this.$router.push(`/store/${id}`);
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
  },
  computed: {
    similiarProduct() {
      return this.$t("message.label-similar-product");
    },

    items() {
      if (this.parent_slug == null) {
        return [
          {
            text: this.$t("message.title-home"),
            disabled: false,
            href: `${process.env.BASE_URL}`,
          },
          {
            text: this.$t("message.title-allCategory"),
            disabled: false,
            href: `${process.env.BASE_URL}category-top`,
          },
          {
            text: this.categoryName,
            disabled: false,
            href: `${process.env.BASE_URL}items/category/c/${this.categorySlug}`,
          },
        ];
      } else {
        return [
          {
            text: this.$t("message.title-home"),
            disabled: false,
            href: `${process.env.BASE_URL}`,
          },
          {
            text: this.$t("message.title-allCategory"),
            disabled: false,
            href: `${process.env.BASE_URL}category-top`,
          },
          {
            text: this.parent_name,
            disabled: false,
            href: `${process.env.BASE_URL}items/category/${this.parent_slug}`,
          },
          {
            text: this.categoryName,
            disabled: false,
            // href: `${process.env.BASE_URL}product/category/${this.categorySlug}`,
            href: `${process.env.BASE_URL}items/category/c/${this.categorySlug}`,
          },
        ];
      }
    },
    itemData() {
      this.categoryName =
        this.$store.state.itemDetail_module.itemDetailResult.category_name;
      this.categorySlug =
        this.$store.state.itemDetail_module.itemDetailResult.category_slug;
      this.slug =
        this.$store.state.itemDetail_module.itemDetailResult.item_name;
      this.parent_name =
        this.$store.state.itemDetail_module.itemDetailResult.category_parent_name;
      this.parent_slug =
        this.$store.state.itemDetail_module.itemDetailResult.category_parent_slug;
      this.specification =
        this.$store.state.itemDetail_module.itemDetailResult.specification;
      return this.$store.state.itemDetail_module.itemDetailResult;
    },
    shopInfo_link() {
      return this.$store.state.itemDetail_module.shopInfo_link;
    },
    similarProduct() {
      return this.$store.state.itemDetail_module.similarProduct;
    },
    storeName() {
      let name =
        this.$store.state.itemDetail_module.itemStoreResult.toLowerCase();
      let splitName = name.split(" ").join("");
      // let join = splitName.join('')
      return this.$store.state.itemDetail_module.itemStoreResult;
    },
    slide() {
      if (this.$store.state.itemDetail_module.itemImageResult.length == 0) {
        return [this.notFound];
      }
      return this.$store.state.itemDetail_module.itemImageResult;
    },
  },
  created() {
    this.itemDetail();
  },
};
</script>
<style>
.desc-class {
  word-break: break-all;
  font-size: 14px;
}
.breadcrumb li a {
  color: grey !important;
  font-size: 12px !important;
}
.v-carousel__controls button span i {
  color: #3f51b5 !important;
  font-weight: bold !important;
  opacity: 80% !important;
}
.row-header {
  background-color: white;
  height: 36px;
  width: 100%;
  margin-top: -15px;
}
.row-image-detail {
  background-color: gray;
  margin-bottom: 2%;
  max-height: 376px;
}
.text-fee {
  font-size: 18px;
  font-weight: 500;
}
.text-price {
  font-size: 16px;
  font-weight: 500;
  margin-top: 19.6px;
}
.text-product {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 3px;
}
.text-merchant {
  font-size: 14px;
  font-weight: 500;
  margin: 3px 3px 3px 0px;
}
</style>
